<div #sidePanel
     [ngClass]="[isPanelOpened ? width : 'w-0 !p-0',  direction === 'rightToLeft' ? 'float-right' : '', !!classes ? classes : '']"
     [ngStyle]="{'transition-duration': closeTimeout + 'ms'}"
     class="sc-card transition-width flex flex-col !bg-transparent !backdrop-blur-lg !h-full overflow-hidden">
  <div *ngIf="isPanelOpened" class="w-full flex justify-between items-center gap-4 pb-2 h-10">
    <div class="sc-text-default text-lg">{{ title }}</div>
    <button (click)="closePanel()" class="hover:scale-110 z-50 absolute top-4 right-4" aria-label="close-panel">
      <i class="bi bi-x-lg text-xl sc-text-default"></i>
    </button>
  </div>
  <div (infiniteScroll)="endOfListHandler()" class="overflow-y-auto overflow-x-hidden" scInfiniteScroll>
    <ng-content></ng-content>
  </div>
</div>
