import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfiniteScrollDirective} from "../../../core/directives/infinite-scroll.directive";

@Component({
  selector: 'sc-side-panel',
  standalone: true,
  imports: [CommonModule, InfiniteScrollDirective],
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidePanelComponent implements AfterViewInit {

  @ViewChild('sidePanel') sidePanel: ElementRef | undefined;
  @Input() title: string = '';
  @Input() direction: 'leftToRight' | 'rightToLeft' = 'leftToRight';
  @Input() width: string = 'w-full';
  @Input() classes: string = '';
  @Output() onClose = new EventEmitter<void>();
  @Output() infiniteScroll = new EventEmitter<void>();
  isPanelOpened: boolean = false;
  readonly closeTimeout: number = 200;


  constructor() {
  }

  @Input() set show(show: boolean) {
    this.isPanelOpened = show;
    if (show) {
      this.sidePanel?.nativeElement?.classList.remove(['!bg-transparent', '!border-transparent']);
    }
  };

  ngAfterViewInit(): void {
    const height = this.sidePanel?.nativeElement.parentElement?.parentElement?.offsetHeight;
    if (height) this.sidePanel?.nativeElement?.setAttribute('style', `height: ${height}px;`);
  }

  closePanel() {
    this.isPanelOpened = false;
    setTimeout(() => {
      this.sidePanel?.nativeElement?.classList.add(['!bg-transparent', '!border-transparent']);
    }, this.closeTimeout)
    this.onClose.emit();
  }

  test($event: any) {

  }

  endOfListHandler() {
    this.infiniteScroll.emit();
  }
}
